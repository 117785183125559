export enum NavigationGroupType {
  None = '',
  Patient = 'patient',
  DonorSource = 'donor-source',
  CordSource = 'cord-source',
  BioBank = 'bio-bank',
}

export enum NavigationItemTitleElementToken {
  Link = 'link',
  Text = 'text',
  Title = 'title',
  NewLine = 'newline',
}

export interface NavigationItemTitleElement {
  token: NavigationItemTitleElementToken;
  text?: string;
  postAdditionalText?: string;
}

export interface NavigationItemModel {
  id: number | null;
  createTimestamp: number;

  disabled: boolean;
  route: string;
  originalRoute: string;
  routeParams: Record<string, any>;
  extraParams: Record<string, any> | null;
  groupType: NavigationGroupType;
  itemType: string | null;
}

export interface NavigationItemUiModel extends NavigationItemModel {
  titleElements: NavigationItemTitleElement[];
}

export interface PatientExtraParams {
  firstName: string;
  lastName: string;
  rid: string;
}

export interface NavigationGroup {
  title?: string;
  disabled?: boolean;
  patient?: PatientExtraParams;

  simple?: boolean;
  items: NavigationItemUiModel[];
}

export type NavigationGroups = NavigationGroup[];
